import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { ChangePasswordForm } from 'layouts';
import { BASE_URL } from 'config';

// eslint-disable-next-line
const ChangePasswordPage = () => (
  <Layout page="user/change-password">
    <Helmet
      title="Change Password"
      meta={[
        {
          name: 'description', content: 'Need to reset your passport? No problem! Reset your Haka Tours password here. ',
        },
        { name: 'og:title', content: 'Change Password' },
        {
          name: 'og:description', content: 'Need to reset your passport? No problem! Reset your Haka Tours password here. ',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/user/change-password/` }]}
    />

    <ChangePasswordForm />
  </Layout>
);

export default ChangePasswordPage;
